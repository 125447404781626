import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import Skeleton from "react-loading-skeleton";

import { DataContext } from "../../context/DataContext";
import { AuthContext } from "../../context/AuthContext";
import PageWrapper from "../../components/PageWrapper";
import sanitizeHtml from "sanitize-html";
import LastSeen from "../../components/LastSeen";
import imgF1 from "../../assets/image/l2/png/featured-job-logo-1.png";
import imgB1 from "../../assets/image/l1/png/feature-brand-1.png";
// import imgB2 from "../../assets/image/l1/png/feature-brand-4.png";
// import imgB3 from "../../assets/image/l1/png/feature-brand-5.png";
// import imgB4 from "../../assets/image/l3/png/github-mark.png";
// import imgB1 from "../../assets/image/l2/png/featured-job-logo-1.png";
import imgB2 from "../../assets/image/l1/png/feature-brand-1.png";
import imgB3 from "../../assets/image/svg/harvard.svg";
import imgB4 from "../../assets/image/svg/mit.svg";
import imgB5 from "../../assets/image/l3/png/universal.png";
import iconL from "../../assets/image/svg/icon-location.svg";
import imageNotFound from "../../assets/image/image-not-found.jpg";

const ClientProfile = ({ location, params }) => {
  const [loading, setLoading] = useState(true);
  const [openJobs, setOpenJobs] = useState([]);
  const [closedJobs, setClosedJobs] = useState([]);
  const { auth } = useContext(AuthContext);
  const { client, getClientDetails, getjobsList } = useContext(DataContext);
  const param = params[`*`];

  const createMarkup = (html) => {
    return {
      __html: sanitizeHtml(html, {
        allowedAttributes: false,
        allowedTags: [
          "address",
          "article",
          "aside",
          "footer",
          "header",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "hgroup",
          "main",
          "nav",
          "section",
          "blockquote",
          "dd",
          "div",
          "dl",
          "dt",
          "figcaption",
          "figure",
          "hr",
          "li",
          "main",
          "ol",
          "p",
          "pre",
          "ul",
          "a",
          "abbr",
          "b",
          "bdi",
          "bdo",
          "br",
          "cite",
          "code",
          "data",
          "dfn",
          "em",
          "i",
          "kbd",
          "mark",
          "q",
          "rb",
          "rp",
          "rt",
          "rtc",
          "ruby",
          "s",
          "samp",
          "small",
          "span",
          "strong",
          "sub",
          "sup",
          "time",
          "u",
          "var",
          "wbr",
          "caption",
          "col",
          "colgroup",
          "table",
          "tbody",
          "td",
          "tfoot",
          "th",
          "thead",
          "tr",
        ],
      }),
    };
  };

  const fetchClientDetails = async (clientId) => {
    if (clientId) {
      await getClientDetails(clientId);
      const jobs = await getjobsList(clientId);
      const foundOpenJobs = jobs.filter((job) =>
        ["pending", "read"].includes(job.status)
      );
      const foundClosedJobs = jobs.filter((job) =>
        ["approved", "closed"].includes(job.status)
      );
      // console.log("Open: ", foundOpenJobs);
      // console.log("Closed: ", foundClosedJobs);
      setOpenJobs(foundOpenJobs);
      setClosedJobs(foundClosedJobs);
    } else {
      navigate(-1);
    }
    setLoading(false);
  };

  useEffect(() => {
    const clientId = param;
    fetchClientDetails(clientId);
    return () => setLoading(true);
  }, []);

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-16 pt-lg-22 pb-lg-27">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-12 mt-13 dark-mode-texts">
                <div className="mb-9">
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    className="d-flex align-items-center ml-4"
                  >
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- back Button End --> */}

            <div className="row ">
              <div className="col-12 col-xl-9 col-lg-8">
                <div className="bg-white rounded-4 pt-11 shadow-9">
                  <div className="d-xs-flex align-items-center pl-xs-12 mb-8 text-center text-xs-left">
                    <Link
                      to="/#"
                      onClick={(e) => e.preventDefault()}
                      className="mr-xs-7 mb-5 mb-xs-0"
                    >
                      {loading ? (
                        <Skeleton width={80} height={80} />
                      ) : client?.imageUrl ? (
                        <img
                          className="rounded-6"
                          src={client?.imageUrl}
                          alt=""
                          width={100}
                        />
                      ) : (
                        <img
                          className="rounded-6"
                          src={imageNotFound}
                          alt=""
                          width={100}
                        />
                      )}
                    </Link>
                    <div className="">
                      <h2 className="mt-xs-n5">
                        {loading ? (
                          <Skeleton width={200} height={44} />
                        ) : (
                          <Link
                            to="/#"
                            onClick={(e) => e.preventDefault()}
                            className="font-size-6 text-black-2 font-weight-semibold"
                          >
                            {client?.company}
                          </Link>
                        )}
                      </h2>
                      {loading ? (
                        <Skeleton width={200} height={24} />
                      ) : (
                        <span className="mb-0 text-gray font-size-4">
                          {client?.account_id}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="pl-12 pt-4 pb-7 pr-12 pr-xxl-24">
                      <div>
                        <hr />

                        <div className="row pt-4">
                          <div className="col-md-5 mb-4">
                            <p className="font-size-4 text-gray mb-0">
                              {loading ? (
                                <Skeleton
                                  height={24}
                                  containerClassName="w-100"
                                />
                              ) : (
                                client?.industry
                              )}
                            </p>
                          </div>

                          <div className="col-md-3 mb-4">
                            {loading ? (
                              <Skeleton
                                height={24}
                                containerClassName="w-100"
                              />
                            ) : (
                              <div className="icon-link d-flex align-items-center">
                                {client?.website ? (
                                  <a
                                    href={client?.website}
                                    target="_blank"
                                    className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                                  >
                                    <i className="fa fa-globe"></i>
                                  </a>
                                ) : null}
                                {client?.github ? (
                                  <a
                                    href={client?.github}
                                    target="_blank"
                                    className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                                  >
                                    <i className="fab fa-github"></i>
                                  </a>
                                ) : null}
                                {client?.linkedin ? (
                                  <a
                                    href={client?.linkedin}
                                    target="_blank"
                                    className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                                  >
                                    <i className="fab fa-linkedin-in"></i>
                                  </a>
                                ) : null}
                                {client?.facebook ? (
                                  <a
                                    href={client?.facebook}
                                    target="_blank"
                                    className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                                  >
                                    <i className="fab fa-facebook-f"></i>
                                  </a>
                                ) : null}
                                {client?.twitter ? (
                                  <a
                                    href={client?.twitter}
                                    target="_blank"
                                    className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                                  >
                                    <i className="fab fa-twitter"></i>
                                  </a>
                                ) : null}
                              </div>
                            )}
                          </div>

                          <div className="col-md-4 mb-4 text-sm-right">
                            <p className="font-size-4 text-gray mb-0">
                              {loading ? (
                                <Skeleton
                                  height={24}
                                  containerClassName="w-100"
                                />
                              ) : (
                                `${client?.city}, ${client?.country}`
                              )}
                            </p>
                          </div>
                        </div>
                        <hr />

                        <div className="pt-4 ">
                          <h4 className="font-size-6 mb-7 text-black-2 font-weight-semibold">
                            Company Description
                          </h4>
                          <div>
                            {loading ? (
                              <div>
                                <p>
                                  <Skeleton
                                    height={24}
                                    containerClassName="w-100"
                                  />
                                </p>
                                <p>
                                  <Skeleton
                                    height={24}
                                    containerClassName="w-100"
                                  />
                                </p>
                                <p>
                                  <Skeleton
                                    height={24}
                                    containerClassName="w-100"
                                  />
                                </p>
                              </div>
                            ) : (
                              <div className="">
                                <p
                                  className="font-size-4 mb-7"
                                  dangerouslySetInnerHTML={createMarkup(
                                    client?.description
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="pt-4 ">
                          {client?.stack ? (
                            <h4 className="font-size-6 mb-7 text-black-2 font-weight-semibold">
                              Tech Stack
                            </h4>
                          ) : null}
                          <div>
                            {loading ? (
                              <p>
                                <Skeleton
                                  height={24}
                                  containerClassName="w-100"
                                />
                              </p>
                            ) : (
                              <div className="">
                                <ul className="list-unstyled d-flex align-items-center flex-wrap">
                                  {client?.stack &&
                                    client.stack
                                      .split(", ")
                                      .map((tech, index) => (
                                        <li key={index}>
                                          <Link
                                            to="/#"
                                            onClick={(e) => e.preventDefault()}
                                            className="bg-polar text-black-2 mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
                                          >
                                            {tech}
                                          </Link>
                                        </li>
                                      ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Company Jobs that are open */}
              <div className="col-12 col-xl-3 col-lg-4 col-md-5 col-sm-6">
                <div className="pt-11 pt-lg-0 pl-lg-5">
                  <h4 className="font-size-6 font-weight-semibold mb-0">
                    Open Jobs
                  </h4>
                  <div>
                    {loading ? (
                      [1, 2, 3, 4, 5].map((index) => (
                        <div key={index}>
                          <p>
                            <Skeleton height={120} containerClassName="w-100" />
                          </p>
                        </div>
                      ))
                    ) : openJobs.length > 0 ? (
                      <ul className="list-unstyled">
                        {openJobs.map((job, index) => (
                          <li key={index} className="border-bottom">
                            <Link
                              to={`/job-details/${job.id}`}
                              className="media align-items-center py-9"
                            >
                              <div className="mt-n4">
                                <h4 className="font-weight-semibold font-size-5 text-black-2 mb-0">
                                  {job?.position}
                                </h4>
                                <div className="mb-0 font-size-4">
                                  <div>
                                    <span className="font-size-3 text-black-2 line-height-2">
                                      {job?.location}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="font-size-3 text-black-2 line-height-2">
                                      <LastSeen
                                        date={new Date(job?.created.toDate())}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="my-8">
                        <span className="font-size-3 text-black-2 line-height-2">
                          No jobs found. Check back soon.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default ClientProfile;
